export default function () {
	$('.m-toggle-block:not(.js-toggle-block-done)').each(function () {
		var wrapper = $(this);
		var allow = true;
		var title = wrapper.find('.js-toggle-title');
		var toggleContent = wrapper.find('.js-toggle-content');

		title.off('click').on('click', function () {
			if (!allow) return false;
			allow = false;

			//change text on toggle
			if (title.is('[data-text-before]')) {
				if (title.is('.active')) {
					title.text(title.attr('data-text-before'));
				}
				else {
					title.text(title.attr('data-text-after'));
				}
			}

			// toggle content class
			toggleContent.toggleClass('active', function () {
				// toggle title class
				title.toggleClass('active');
				// allow
				allow = true;
			});
			return false;

		});

		// done
		wrapper.addClass('js-toggle-block-done');

	});
}
